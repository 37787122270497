
import { Http } from "./http/base"
import { res_list } from '@/types/global'

export interface banner {
	id:number
	title:string
	image:string
	h5_image:string
	link:string
}

export interface subject_content{
	title:string
	desc:string
	service:string
	price:string | number
	begin_time:string
	end_time:string
}

export interface subject {
	id:number
	title:string
	cover:string
	position:position
	chapter:number
	content:subject_content[]
	examination:number
}

export interface position{
	id:number
	title:string
	subject?:number
}

export interface pass{
	id:number
	status:number
	username:string
	subject:string
	content:{row:string,size:number}[]
	create_time:string
}

class Home extends Http {
	get_banner(){
		return this.get<banner[]>({},'/banner/')
	}

	get_position(data:{page:number,page_size?:number,is_hot?:number}){
		return this.get<res_list<position>>({...data},'/position/')
	}

	get_subject(data:{page:number,page_size:number,is_hot?:number}){
		return this.get<res_list<subject>>({...data},'/subject/')
	}

	get_pass(data:{page:number,page_size:number}){
		return this.get<res_list<pass>>({...data},'/pass/')
	}

}


export const api_home = new Home('')
