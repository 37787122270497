






import { Vue, Component } from "vue-property-decorator";
import Rview from "@/components/routerView/index.vue";
import ChannelService from "./util/channel";
ChannelService.loadScript();
ChannelService.boot({
  pluginKey: "c7f88e03-74b4-41f1-81ae-733395849ca9", // fill your plugin key
});

@Component({
  components: {
    Rview,
  },
})
export default class extends Vue {}
