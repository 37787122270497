
import { UserModule } from '@/store/user'
import { req_list, res_list } from '@/types/global'
import StorageDb from '@/util/storage'
import { position, subject } from './home'
import { Http } from "./http/base"



export interface user_info {
	username: string
	phone: string
	id: number
	real_name:string
	avatar:string
	address:string
	address_code:string
	address_detail:string
	email:string
	is_show: 1 | 0
}


export interface user_pay {
	id:number
	begin_time:string
	end_time:string
	position:position
	price:number
	service:string
	status:number
	subject:subject
}

export interface user_course {
	id:number
	end_time:string
	subject:subject
	position:position
	exam_count:number
	solved_count:number
	correct_count:number
	solved_rate:number
	correct_rate:number
	price:number
	status:number
}

export interface user_score {
	id:number
	user:number
	amount:number
	balance:number
	type:number
	order_id:string
	create_time:string
	update_time:string
	desc:string
}

export interface user_score_info {
	total:number
	add:number
	dec:number
}

export interface user_chapter_left {
	id:number
	chapter_list:chapter_list_info[]
	title:number
	progress_rate:number
}

export interface chapter_list_info {
	exam_count: number
	solved_count: number
	correct_count: number
	solved_rate: number
	correct_rate: number
	last?: {
		id:number
		level:number
		chapter_id:number
	}
}

export interface last {
	chapter:string
	chapter_id:number
	id:number
	level:number
	section:string
	title:string
}

export interface user_chapter_right {
	id?:number
	end_time:string
	subject?:subject
	position?:position
	correct_rate:number
	correct?:chapter_info_correct[]
	progress_rate:number
	rogress?:chapter_info_correct[]
	last_chapter?:chapter_list_info
	last?:last
}

export interface chapter_info_correct {
	solved_count:number
	correct_count:number
	correct_rate:number
}

export interface chapter_info_progress {
	exam_count:number
	solved_count:number
	solved_rate:number
}

export interface exam_list {
	id:number
	title:string
	content:string
	type:number
	position:position
	chapter:{
		id:number
		title:string
	}
	subject:subject
	explain:string
	collect:number
	notebook:string
	result:{
		answer:string
		bool_answer:number
		is_bool:number
	}
	order:number
}

export interface answer_data {
	exam_id:number
	answer?:string
	boolean?:number
}

class User extends Http {
	get_pay = (data: req_list) => {
		return this.get<res_list<user_pay>>({ ...data,page_size:5 }, '/user/order/')
	}

	get_score = (data: req_list) => {
		return this.get<res_list<user_score>>({ ...data,page_size:5 }, '/user/score/')
	}

	get_score_info = () => {
		return this.get<user_score_info>({},'/user/scorelook')
	}

	get_course = (data: req_list) => {
		return this.get<res_list<user_course>>({...data, page_size:5}, '/user/course/')
	}

	get_chapter_left = (data:req_list) => {
		return this.get<res_list<user_chapter_left>>({...data, page_size:1000},'/user/chapterlist/')
	}

	get_chapter_right = (id:number) => {
		return this.get<user_chapter_right>({},`/user/course/${id}/`)
	}

	get_collect_list = (data: req_list) => {
		return this.get<res_list<exam_list>>({page_size:1000, ...data}, '/user/collectlist/')
	}

	get_number_list = (data: req_list) => {
		return this.get<res_list<exam_list>>({page_size:20, ...data}, '/user/numberlist/')
	}

	answer_collect_or_number = (data:answer_data) => {
		return this.post(data,'/user/answer_collect')
	}

	get_user_info(){
		const data = StorageDb.getLocal('fcm_reg_token') === null ?
		{} : { fcm_reg_token:StorageDb.getLocal('fcm_reg_token') };  //app
		return this.get<user_info>(data,'/user/info/')
	}

	get_exam_list = (data: req_list) => {
		return this.get<res_list<exam_list>>({page_size:1000,...data},'/user/examlist/')
	}

	answer_exam = (data:answer_data) => {
		return this.post(data,'/user/answer')
	}

	answer_finish = (data:{level:number,chapter:number,course_id:number}) => {
		return this.post(data,'/user/finish')
	}

	post_collect(exam_id:number){
		return this.post({exam_id},'/user/collect')
	}

	post_note(data:{note:string,exam_id:number}){
		return this.post(data,'/user/book')
	}

	edit_pass(data:{
		old_password:string
		new_password1:string
		new_password2:string
	}){
		return this.patch(data,'/user/password/')
	}

	edit_user(data:any){
		return this.patch(data,`/user/${UserModule.info?.id}/`)
	}

	check_password(data:{password:string}){
		return this.post(data,`/user/check_password/`)
	}

	unregister(){
		return this.post({},`/user/unregister`)
	}
}


export const api_user = new User('/user/')
