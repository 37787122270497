import { req_list, res_list } from '@/types/global'
import { Http } from "./http/base"
import { subject } from './index'

export interface pay_join{
	order_id:string
	merchant_id:string
}

class Subject extends Http {
	get_list = (data: req_list) => {
		return this.get<res_list<subject>>({ page_size:9,...data })
	}

	get_info(id: number) {
		return this.get<subject>({}, `/subject/${id}/`)
	}

	pay_join(data: pay_join){
		return this.post(data, `/user/pay`)
	}

	check_info(data: {subject:number,index:number | string}){
		return this.post(data, `/user/check`)
	}
}


export const api_subject = new Subject('/subject/')