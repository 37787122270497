/* eslint-disable */
import "babel-polyfill";
// import "vue-tsx-support/enable-check";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Loading } from "@/mixin/loading";
import "@/assets/style/reset.less";
import "@/assets/style/element_phone.less";
import "@/assets/style/reset_ele.less";
import "element-ui/lib/theme-chalk/display.css";
import "@/assets/style/element-variables.scss";
import "@/util/rem.js";
import Element, { Message } from "element-ui";
import locale from "element-ui/lib/locale/lang/ko";
import { OtherModule } from "@/store/other";
import { UserModule } from "@/store/user";
// import VConsole from 'vconsole';
// let vConsole = new VConsole();
OtherModule.checkMobile();
if (OtherModule.is_mobile) {
  document.documentElement.style.fontSize = document.documentElement.clientWidth / 3.6 + "px";
  console.log(document.documentElement.style.fontSize);
  // location.reload()
} else {
  document.documentElement.style.fontSize = "100px";
}
UserModule.get_info();
Vue.use(Element, { locale });
Vue.mixin(Loading);
IMP.init("imp92480683");
Kakao.init("57f47964e8de91ae07b6c08811082793");
Vue.config.productionTip = false;

Vue.prototype.$message = function(msg: string) {
  return Message({
    message: msg,
    duration: 2000,
  });
};
Vue.prototype.$message.success = function(msg: string) {
  return Message.success({
    message: msg,
    duration: 2000,
  });
};
Vue.prototype.$message.error = function(msg: string) {
  return Message.warning({
    message: msg,
    duration: 2000,
  });
};
export const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
