
import { OtherModule } from '@/store/other.ts'

(function() {
    window.onresize = function() {
      OtherModule.checkMobile()
      if(OtherModule.is_mobile){
        document.documentElement.style.fontSize =
        document.documentElement.clientWidth/3.6 + "px";
      }else{
        document.documentElement.style.fontSize = '100px'
      }
    };
  })();
