import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location: import("vue-router").RawLocation) {
// 	//@ts-ignore
// 	return originalPush.call(this, location).catch((err: any) => err)
// }
const routes: Array<RouteConfig> = [
	{
		path: '*',
		redirect:"/404",
		
	},
	{
		path:'/404',
		name:'error',
		component: () => import('@/views/404/index.vue')
	},
	{
		path: '/',
		name: 'home',
		redirect: '/',
		meta: {
			cache: true
		},
		component: () => import('@/components/global/index.vue'),
		children: [
			{
				path: '',
				name: 'index',
				meta: {
					cache: true
				},
				component: () => import('@/views/index/index.vue'),
			},
			{
				path: '/login',
				name: 'login',
				redirect: '/login/signin',
				meta: {
					cache: true
				},
				component: () => import('@/views/login/index.vue'),
				children: [
					{
						path: 'signin',
						name: 'signin',
						component: () => import('@/views/login/signin/index.vue'),
					},
					{
						path: 'signup',
						name: 'signup',
						component: () => import('@/views/login/signup/index.vue'),
					},
					{
						path: 'findusername',
						name: 'findusername',
						component: () => import('@/views/login/findusername/index.vue'),
					},
					{
						path: 'findpassword',
						name: 'findpassword',
						component: () => import('@/views/login/findpassword/index.vue'),
					},
				]
			},
			{
				path: '/user',
				name: 'user',
				meta: {
					// title: '마이페이지',
					cache: false
				},
				component: () => import('@/views/user/index.vue'),
				redirect: '/user/course',
				children:[
					{
						path:'collect/:id/',
						name:'collect',
						meta:{
							title:'즐겨찾기'
						},
						component: () => import('@/views/user/collect/index.vue'),
					},
					{
						path:'number/:id/',
						name:'collect',
						meta:{
							title:'number_exam'
						},
						component: () => import('@/views/user/number/index.vue'),
					},
					{
						path:'score',
						name:'score',
						meta:{
							title:'포인트 내역'
						},
						component: () => import('@/views/user/score/index.vue'),
					},
					{
						path:'course',
						name:'course',
						meta:{
							title:'수강과목'
						},
						component: () => import('@/views/user/course/index.vue'),
					},
					{
						path:'course/:id/exam/:chapterId/',
						name:'exam',
						meta:{
							title:'solved_exam'
						},
						component: () => import('@/views/user/course/exam/index.vue'),
					},
					{
						path:'course/:id/result/:chapterId/',
						name:'course_result',
						meta:{
							title:'course_result'
						},
						component: () => import('@/views/user/course/result/index.vue'),
					},
					{
						path:'course/:id',
						name:'courseInfo',
						meta:{
							
						},
						component: () => import('@/views/user/course/info/index.vue'),
					},
					{
						path:'pay',
						name:'pay',
						meta:{
							title:'결제내역'
						},
						component: () => import('@/views/user/pay/index.vue')
					}
				]
			},
			{
				path: '/service/:type',
				name: 'service',
				meta: {
					cache: false
				},
				component: () => import('@/views/service/index.vue')
			},
			{
				path: '/group',
				name: 'group',
				meta: {
					title: '그룹스터디',
					cache: true
				},
				component: () => import('@/views/group/index.vue'),
				redirect: '/group/team',
				children:[
					{
						path: 'team',
						name: 'team',
						meta: {
							title: '그룹방'
						},
						component: () => import('@/views/group/team/index.vue'),
					},
					{
						path: 'random',
						name: 'random',
						meta: {
							title: '랜덤방'
						},
						component: () => import('@/views/group/random/index.vue'),
					},
					{
						path: 'user/:group_id/',
						name: 'user',
						meta: {
							title: '대기실'
						},
						component: () => import('@/views/group/user/index.vue'),
					},
					{
						path: 'exam/:group_id/',
						name: 'exam',
						meta: {
							title: 'solved_exam'
						},
						component: () => import('@/views/group/exam/index.vue'),
					},
					{
						path: 'result/:group_id/',
						name: 'result',
						meta: {
							title: 'group_result'
						},
						component: () => import('@/views/group/result/index.vue'),
					},
				]
			},
			{
				path:'/other',
				name:'other',
				redirect:'/other/setting',
				component: () => import('@/views/other/index.vue'),
				meta: {
					cache: true
				},
				children:[
					{
						path:'setting',
						name:'setting',
						component: () => import('@/views/other/setting/index.vue')
					},
					{
						path: 'pay/:type',
						name: 'pay',
						component: () => import('@/views/other/pay/index.vue'),
					}
				]
			},
			{
				path: '/customer',
				name: 'customer',
				meta: {
					title: '고객센터',
					cache: true
				},
				component: () => import('@/views/customer/index.vue'),
				redirect: '/customer/notice',
				children: [
					{
						path: 'notice',
						name: 'notice',
						meta: {
							title: '공지사항'
						},
						component: () => import('@/views/customer/notice/index.vue'),
					},
					{
						path: 'problem',
						name: 'problem',
						meta: {
							title: '자주 묻는 질문'
						},
						component: () => import('@/views/customer/problem/index.vue'),
					},
					{
						path: 'answer',
						name: 'answer',
						meta: {
							title: '1:1 문의'
						},
						component: () => import('@/views/customer/answer/index.vue'),
					},
					{
						path: 'report',
						name: 'report',
						meta: {
							title: '정오신고'
						},
						component: () => import('@/views/customer/report/index.vue'),
					},
				]
			},
			{
				path: '/subject/:position_id',
				name: 'subject',
				meta: {
					title: '응시직렬',
					cache: true
				},
				component: () => import('@/views/subject/index.vue'),
				redirect: '/subject/:position_id/list',
				children: [
					{
						path: 'list',
						name: 'subject',
						meta: {
							title: 'list'
						},
						component: () => import('@/views/subject/list/index.vue'),
					},
					{
						path: 'info/:id',
						name: 'subject',
						meta: {
							title: 'info'
						},
						component: () => import('@/views/subject/info/index.vue'),
					},
				]
			},
		]
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
