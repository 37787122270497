/* eslint-disable */
import { UserModule } from '@/store/user'
import { req_list, res_list } from '@/types/global'
import { Http } from "./http/base"
import { position, subject } from './home'

export interface group {
    id: number
    user: {
        id: number,
        username: string
    }
    type: string
    name: string
    order: number
    timer: number
    userlist: {
        id: number,
        username: string
    }
    subject: {
        id: number
        title: string
    }
    chapter: {
        id: number
        title: string
    }
    create_time: string
}

export interface create_group {
    type: number
    name?: string
    order?: number
    timer?: number
    userlist?: string
    subject: number | string
    chapter: number | string
}

export interface group_exam {
    id:number
	title:string
	content:string
	type:number
	position:position
	chapter:{
		id:number
		title:string
	}
	subject:subject
	explain:string
	collect:number
	notebook:string
	result:{
		answer:string
		bool_answer:number
		is_bool:number
	}
    order:number
}

export interface answer_group_data {
	exam_id:number
	answer?:string
	boolean?:number
    group_id:number
}

class Customer extends Http {
    get_group = (data: req_list) => {
        return this.get<res_list<group>>({ page_size: 10, ...data }, '/user/group/')
    }

    get_group_detail = (id:number) => {
        return this.get({}, `/user/group/${id}/`)
    }

    create_group = (data: create_group) => {
        return this.post(data, '/user/group/')
    }

    check_group_name = (data: { name: string | undefined }) => {
        return this.post(data, '/user/check_group')
    }

    search_user = (data: { username: string }) => {
        return this.get<{ id: number, username: string }[]>(data, '/user/search/')
    }

    check_user = (data: { group_id: number }) => {
        return this.get<[]>(data, '/user/group/look/')
    }

    begin_group = (data: {group_id: number}) => {
        return this.post(data, '/user/group/begin/')
    }

    out_group = (data: {group_id: number}) => {
        return this.get(data, '/user/group/signout/')
    }

    delete_group = (data: {group_id: number}) => {
        return this.post(data, '/user/group/delete/')
    }

    check_group_status = (data: { group_id: number }) => {
        return this.get(data, '/user/group/status/')
    }

    get_user_course = () => {
        return this.get<[]>({}, '/user/usercourse/')
    }

    get_group_exam = (data:req_list) => {
        return this.get<res_list<group_exam>>({page_size:1000,...data},'/user/group_exam/')
    }

    add_group = (data:{group_id:number}) => {
        return this.post(data,'/user/addgroup')
    }

    answer_group_exam = (data:answer_group_data) => {
		return this.post(data,'/user/answer_group')
	}

    answer_group_finish = (data:{group_id:number}) => {
		return this.get(data,'/user/finish_answer')
	}

    group_user_score = (data:{group_id:number}) => {
		return this.get(data,'/user/group_user/')
	}
}


export const api_group = new Customer('')
